@import '../_vars/vars';

// Primary actions such as save and optimise
.primaryBtn {
  padding: 0.6rem 1rem;
  background:transparent;
  color: $vDefaultText-color;
  border-radius: 2px;
  border: thin solid $vPink-color !important;
  line-height: 1.2em;
  transition: all 0.5s;

  &:hover {
    cursor: pointer !important;
    background: #A18FEB40;
  }
}

.chunky{
  color: white !important;
  background-color: $vPink-color;
}