.box-input {
  width: 100%;
  margin: 0rem auto 1rem auto;
  text-align: center;
  overflow: hidden;

  .box-input-file {
    cursor: pointer;
    position: relative;
    border: 1px dashed #fff;
    padding: 1rem;
    display: block;
    margin: 1rem auto 1rem auto;
    border-radius: 4px;

    .box-file {
      cursor: pointer;
      position: absolute;
      margin: 0;
      padding: 0;
      outline: none;
      opacity: 0;
    }

    p {
      word-break: break-word;
      color: white;
      margin: 0 0 1rem 0;
      font-size: 1.1rem;
    }

    .label {
      font-size: 0.85rem;
      color: #999 !important
    }
  }
  .iconWrap{
    i{
      font-size: 22px;
    }
  }
}

.box {
  border: 1px dashed #fff;
}
