@import '../_vars/vars';
// Secondary actions such as cancel and edit

.secondaryBtn {
  padding: 0.6rem 1rem;
  background: transparent;
  color: $vDefaultText-color;
  border-radius: 0.2rem;
  border: 1px solid #5A5A5A !important;
  line-height: 1.2em;
  transition: all 0.5s;

  &:hover {
    cursor: pointer !important;
    border: 1px solid  #A18FEB !important;
    background: #A18FEB40 0% 0% no-repeat padding-box;
  }
}
