@use '../../../../node_modules/@angular/material/index' as mat;
@import 'vars';

$mat-font-stack: $font-stack;
$light-primary-text: white;
$dark-primary-text: rgba(black, 0.87);

$mat-optimum-primary: (
  100: map-get($colors, 'primary-light'),
  500: map-get($colors, 'primary'),
  700: map-get($colors, 'primary-dark'),
  contrast: (
    100: $dark-primary-text,
    500: $dark-primary-text,
    700: $light-primary-text,
  ),
);

$mat-optimum-accent: (
  100: map-get($colors, 'accent-light'),
  500: map-get($colors, 'accent'),
  700: map-get($colors, 'accent-dark'),
  contrast: (
    100: $light-primary-text,
    500: $light-primary-text,
    700: $light-primary-text,
  ),
);

// Based off $mat-warn (native to Angular Material)
$mat-optimum-warn: (
  100: map-get($colors, 'warn-light'),
  500: map-get($colors, 'warn'),
  700: map-get($colors, 'warn-dark'),
  contrast: (
    100: $dark-primary-text,
    500: $light-primary-text,
    700: $light-primary-text,
  ),
);

$mat-optimum-grey: (
  700: map-get($colors, 'grey-light'),
  800: map-get($colors, 'grey'),
  900: map-get($colors, 'grey-dark'),
  contrast: (
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);

// TODO: 'menu' should be renamed
$dark-theme-background-palette: (
  status-bar: black,
  app-bar: map_get($mat-optimum-grey, 900),
  background: map_get($background-colors, 'menu'),
  hover: rgba(white, 0.04),
  card: #1e1e1e !important,
  dialog: map_get($mat-optimum-grey, 800),
  disabled-button: rgba(white, 0.12),
  raised-button: map-get($mat-optimum-grey, 800),
  focused-button: rgba(white, 0.12),
  selected-button: map_get($mat-optimum-grey, 900),
  selected-disabled-button: map_get($mat-optimum-grey, 800),
  disabled-button-toggle: black,
  unselected-chip: map_get($mat-optimum-grey, 700),
  disabled-list-option: black,
);
