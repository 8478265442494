// Typography
$font-stack: 'IBM Plex Sans', 'Roboto', sans-serif;
$root-font-size: 16px;

// Colors - Generic
$colors: (
  'primary': #A18FEB,
  // based off the above color https://material.io/resources/color/
  'primary-light': #A18FEB40,
  'primary-dark': #008d58,
  'accent': #049fb8,
  'accent-light': #e7ecee,
  'accent-dark': #a0afb8,
  'grey': #242629,
  'grey-light': #969696,
  'grey-dark': #141414,
  'warn': #e57373,
  'warn-light': #ffcdd2,
  'warn-dark': #d32f2f,
  'white': '#fff',
  'white-80': rgba(255, 255, 255, 0.8),
  'black': '#000',
  'black-variant': #1e1e1e,
);

$colors-v2: (
  'primary': #A18FEB,
  'primary-light': #A18FEB40,
  'boston-blue': #3d9bbe,
  'fuchsia-blue': #823dbe,
  'fuchsia-pink': #be3daf,
  'apple': #6abe3d,
  'earls-green': #bebe3d,
  'mojo': #be3d3d,
  'copper': #be6f3d,
);

$colors-text: (
  'soft': #bababa,
);

$alert-colors: (
  'red': #cb3a3a,
  'amber': #d3861e,
  'green': #408452,
);

$background-colors: (
  'body': #121212,
  'card': #1e1e1e,
  'menu': #2f2f2f,
  'menu-item': rgba(255, 255, 255, 0.08),
  'table-row-hover': #2f2f2f,
  'borders': rgba(0, 0, 0, 0.12),
);

// Colors - Background
:root {
  @each $name, $color in $colors {
    --#{$name}: #{$color};
  }

  @each $name, $color in $alert-colors {
    --alert-#{$name}: #{$color};
  }
}

.description{
  color: #C0C1C3;
}



//$primary: #A48038;
//$primary-light: #e4d9c3;
//$primary-dark: #926d2b;
//
//$accent: #b0bec5;
//$accent-light: #e7ecee;
//$accent-dark: #a0afb8;
//
//$grey: #242629;
//$grey-light: #616161;
//$grey-dark: #141414;
//
//$warn: #f44336;
//$warn-light: #ffcdd2;
//$warn-dark: #d32f2f;

// Legacy
$primary-color: hsl(240, 11%, 15%);
$vDefaultText-color: hsl(0, 0%, 100%);
$vPink-color: map-get($colors, 'primary');
$vContent-bg: hsl(240, 7%, 19%);
$text-primary-color: #fff; //headers, values
$text-secondary-color: #ccc; //labels
$text-tertiary-color: #bcbdbf; //sub-title
$primary-color: #c7a680;
$primary-color-active: #aa8f70;
$font-size-btn: 0.9rem;
$font-size-label: 0.8rem;
$border-radius-btn: 0.2rem;
$background-dark: map_get($background-colors, 'body');
$background-card-dark: #262626;
$white: #fff;
$light-border: #fff;
$darkGrey-border: #3e3e3e;
$dark-border: #262626;
$dark-text-color: #121212;
