.mat-progress-bar-background {
  fill: #242629
}

.mat-progress-bar-buffer {
  background-color: #242629
}

// TODO: check later (already in mat theme but different hues)
.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #cfd8dc
}

.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #cfd8dc
}
