/* TODO: check what are these? the subclasses (i.e.) aren't mat classes */
.cdk-overlay-pane .invData {
  padding: 2rem 0 1rem;
}

.cdk-overlay-pane .defaultTable {
  margin: 0 auto;
}

@media only screen and (min-width: 1900px) {
  .cdk-overlay-pane .defaultTable {
    width: 1500px !important;
  }
}

@media only screen and (max-width: 1899px) and (min-width: 1620px) {
  .cdk-overlay-pane .defaultTable {
    width: 1200px !important;
  }
}

@media only screen and (max-width: 1619px) and (min-width: 1510px) {
  .cdk-overlay-pane .defaultTable {
    width: 1100px !important;
  }
}

@media only screen and (max-width: 1509px) and (min-width: 1401px) {
  .cdk-overlay-pane .defaultTable {
    width: 1054px !important;
  }
}

@media only screen and (max-width: 1400px) {
  .cdk-overlay-pane .defaultTable {
    width: 768px !important;
  }
}

.cdk-overlay-pane .mediaInv {
  border: thin solid #666;
}
