// Material
@use '@angular/material' as mat;
@import '../_vars/material-vars';

@include mat.core();

$app-primary: mat.define-palette($mat-optimum-primary);
$app-accent: mat.define-palette($mat-optimum-accent);
$app-warn: mat.define-palette($mat-optimum-warn);

$optimum-app-theme: mat.define-dark-theme(
  $app-primary,
  $app-primary,
  $app-warn
);

@function modify-background($theme, $bg-palette) {
  $theme: map-merge(
    $theme,
    (
      color: (
        background: $bg-palette,
      ),
    )
  );
  $theme: map-merge(
    $theme,
    (
      background: $bg-palette,
    )
  );
  @return $theme;
}

// TODO: do I still need this ? (: Prob not because I took mat-typography out;
//  is there any way to delete all typography related mat-classes? or should I just purge and do not care?
$custom-typography: mat.define-typography-config(
  $font-family: $mat-font-stack,
  $display-4:
    mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $display-3:
    mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $display-2:
    mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $display-1: mat.define-typography-level(34px, 40px, 400),
  $headline: mat.define-typography-level(28.83px, 43.245px, 700),
  $title: mat.define-typography-level(25.63px, 38.445px, 700),
  $subheading-2: mat.define-typography-level(22.78px, 34.17px, 700),
  $subheading-1: mat.define-typography-level(20.25px, 30.375px, 700),
  $body-2: mat.define-typography-level(12.64px, 18.96px, 400),
  $body-1: mat.define-typography-level(14.22px, 1.5, 400),
  $caption: mat.define-typography-level(14.22px, 21.33px, 400),
  $button: mat.define-typography-level(14.22px, 1.5, 700),
  // Line-height must be unit-less fraction of the font-size.
  $input: mat.define-typography-level(inherit, 1.125, 400),
);

@include mat.all-component-themes(
  modify-background($optimum-app-theme, $dark-theme-background-palette)
);
@include mat.all-component-typographies($custom-typography);

// Font Test
.mat-typography.ibm-plex-sans {
        font-family: 'IBM Plex Sans', sans-serif!important;
        $font-stack: 'IBM Plex Sans', sans-serif;

        $custom-typography: mat.define-typography-config(
                $font-family:   $font-stack,
                $display-4:     mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
                $display-3:     mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
                $display-2:     mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
                $display-1:     mat.define-typography-level(34px, 40px, 400),
                $headline:      mat.define-typography-level(24px, 32px, 400),
                $title:         mat.define-typography-level(20px, 32px, 600),
                $subheading-2:  mat.define-typography-level(16px, 28px, 600),
                $subheading-1:  mat.define-typography-level(15px, 24px, 600),
                $body-2:        mat.define-typography-level(14px, 24px, 600),
                $body-1:        mat.define-typography-level(14px, 20px, 400),
                $caption:       mat.define-typography-level(12px, 20px, 400),
                $button:        mat.define-typography-level(14px, 14px, 600),
                        // Line-height must be unit-less fraction of the font-size.
                $input:         mat.define-typography-level(inherit, 1.125, 400)
        );
        @include mat.all-component-typographies($custom-typography);
}

.mat-typography.roboto {
        font-family: 'Roboto', sans-serif!important;
        $font-stack: 'Roboto', sans-serif;

        $custom-typography: mat.define-typography-config(
                $font-family:   $font-stack,
                $display-4:     mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
                $display-3:     mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
                $display-2:     mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
                $display-1:     mat.define-typography-level(34px, 40px, 400),
                $headline:      mat.define-typography-level(24px, 32px, 400),
                $title:         mat.define-typography-level(20px, 32px, 600),
                $subheading-2:  mat.define-typography-level(16px, 28px, 600),
                $subheading-1:  mat.define-typography-level(15px, 24px, 600),
                $body-2:        mat.define-typography-level(14px, 24px, 600),
                $body-1:        mat.define-typography-level(14px, 20px, 400),
                $caption:       mat.define-typography-level(12px, 20px, 400),
                $button:        mat.define-typography-level(14px, 14px, 600),
                        // Line-height must be unit-less fraction of the font-size.
                $input:         mat.define-typography-level(inherit, 1.125, 400)
        );
        @include mat.all-component-typographies($custom-typography);
}

