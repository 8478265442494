$scrollbar-width: 7px;
$scrollbar-padding: 4px;

::-webkit-scrollbar {
  width: $scrollbar-width + 2 * $scrollbar-padding;
}

::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 10px;
  border: #{$scrollbar-padding} solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}
