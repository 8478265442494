.searchfield {
  .custom-search {
    position: absolute;
    right: 0px;
    top: -5px;
  }

  #express-form-typeahead {
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 37px;
    margin: 3px 0;
    padding: 0.2rem 2rem 0.2rem 1rem;
    position: relative;
    -webkit-transition: width 400ms ease, background 400ms ease;
    transition: width 400ms ease, background 400ms ease;
    width: 0rem;
    color: rgba(255, 255, 255, 1);
    font-size: 1rem;
  }

  #express-form-typeahead:focus {
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    cursor: text;
    outline: 0;
    width: 25rem;
  }

  .search-btn {
    display: none;
  }

  input[type="search"] {
    -webkit-appearance: textfield;
  }
}
