@import '../_vars/vars';

// Primary actions such as save and optimise
.linkBtn {
  padding: 0.6rem 1rem;
  background:transparent;
  color:#bababa;
  line-height: 1.2em;
  transition: all 0.5s;
  &:hover {
    cursor: pointer !important;
  }
  i{
    transform: translateY(-1px);
  }
}
