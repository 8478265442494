.cancelBtn {
    padding: 0.6rem 1rem;
    background: transparent;
    color: $vDefaultText-color;
    border-radius: 0.2rem;
    border: 1px solid #5A5A5A !important;
    line-height: 1.2em;
    transition: all 0.5s;
    &:hover {
      cursor: pointer !important;
      background: #FFFFFF1A;
    }
  }
  