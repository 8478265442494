.mat-table {
  width: 100%;
  background-color: transparent !important;

  .mat-header-cell {
    font-weight: 500 !important;
    color: #ccc;
    border-color: rgba(255, 255, 255, 0.12);
    word-break: break-all;
  }

  .mat-cell {
    border-color: rgba(255, 255, 255, 0.12);
  }

  .mat-header-cell,
  th.mat-header-cell,
  .mat-cell,
  td.mat-cell {
    color: #fff;
    padding: 0.5rem;
    font-weight: 300;
    //&:first-of-type {
    //  padding-left: 1.25rem;
    //}
    //
    //&:last-of-type {
    //  padding-right: 1.25rem;
    //}
  }
}
