@import 'src/assets/scss/_vars/vars';

* {
  box-sizing: border-box;
}

html {
  // TODO: need to sync it with a SCSS map, tailwind and Material (find a way to get a source of truth for all of them)
  background-color: map_get($background-colors, 'body');
  color: #fff;
  font-size: $root-font-size;
  line-height: 1.5;
}

body {
  margin: 0;
  font-family: $font-stack;
  @include text-body;
  line-height: 1.5;
}

a {
  color: inherit;
}

input {
  font-family: $font-stack;
  @include text-body;
  padding: 0;
}

table {
  border-spacing: 0;
}

iframe {
  border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

button {
  background: none;
  border: none;
  color: $white;
}

.eva {
  @include text-body;
  vertical-align: middle;
}

// Hide spinbox
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
// Hide spinbox

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}
