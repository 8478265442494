@import '~tippy.js/dist/tippy.css';
.tippy-box {
  background-color: map-get($background-colors, 'menu');
  overflow: hidden;
  @include text-small;

  .tippy-content {
    padding: 0.25rem 0.5rem;
  }
}
