.defaultform {
  width: 100%;
  padding: 2rem 0;
  // TODO: check if it's needed?
  padding-top: 0px;

  .formBtnWrap {
    float: right;
    overflow: hidden;
    padding: 2% 3%;
    clear: both;
  }

  .formItem {
    width: 100%;
    float: left;
    padding: 2% 3%;

    label {
      color: #ccc;
      font-weight: lighter;
    }

    input {
      width: 100%;
      background: transparent;
      border: none;
      border-bottom: 0.1rem solid rgba(255, 255, 255, 1);
      color: rgba(255, 255, 255, 1);
      padding: 0.5rem 0.2rem 0.5rem 0rem;
      text-transform: capitalize;
      // todo: check if it's needed
      text-transform: none !important;

      &:disabled {
        color: rgba(255, 255, 255, 0.5);
        border-bottom-color: rgba(255, 255, 255, 0.5);
      }
    }

    input#client, input#outputLocation, input#permutationsLocation, input#assetLocation, input#markets {
      background: transparent url('/assets/img/searchInput.svg') no-repeat right;
      background-size: 1rem;
    }

    .staticInputField {
      border: none;
    }

    ::-webkit-input-placeholder, { /* Chrome/Opera/Safari */
      color: rgba(255, 255, 255, 0.65);
    }

    ::-moz-placeholder { /* Firefox 19+ */
      color: rgba(255, 255, 255, 0.65);
    }

    :-ms-input-placeholder { /* IE 10+ */
      color: rgba(255, 255, 255, 0.65);
    }

    :-moz-placeholder { /* Firefox 18- */
      color: rgba(255, 255, 255, 0.65);
    }

    textarea {
      background: transparent;
      width: 100%;
      margin: 0.3rem 0 0 0;
      padding: 0.5rem;
      color: white;
      min-height: 5rem;
      text-transform: none !important;
    }
  }
}
