.notifier {
  color: #fff;
  font-weight: normal;
  
    &-info{
   box-shadow: 2px 2px 4px #17a2b8;
     h1 {
       font-size: 1rem;
       color: #17a2b8;
     }
  }

  &-success {
    box-shadow: 2px 2px 4px #408452;
      h1 {
        font-size: 1rem;
        color: #408452;
      }

      
   
  }

  &-error{
    // box-shadow: 2px 2px 4px #CB3A3A; 
    border-color: #333;
    border-radius: 1px;
    background-color: #1E1E1E;
    h1{
      font-size: 1rem;
      color: #D77976;
    }
  
    // background-color: #dc3545;
  }

  &-warning {
    box-shadow:2px 2px 4px #E57373;
     h1 {
       font-size: 1rem;
       color: #E57373;
     }
  }
}
.mat-flat-button{
  // border: 1px solid #E57373 !important;
  width: 56px;
  height: 31px;
  display: block;
  float: right;
  line-height:0 !important;
}