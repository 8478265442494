// Mixins
@import '_mixins/typography';
@import '_vars/vars';
@import 'base/resets';

// Vendor
//@tailwind base;
@tailwind utilities;
@import '~eva-icons/style/eva-icons.css';
@import '~font-awesome/css/font-awesome.css';
@import '~ngx-toastr/toastr.css';
@import '~@videogular/ngx-videogular/fonts/videogular.css';
@import 'vendor/ng-select';
@import 'vendor/ngx-datatable';
@import 'vendor/tippy';

@import 'material/core';
// Material Overrides (`mat-${componentName}`)
@import 'material/mat-button';
@import 'material/mat-button-toggle';
@import 'material/mat-card';
@import 'material/mat-chips';
@import 'material/mat-dialog';
@import 'material/mat-drag-drop';
@import 'material/mat-grid-list';
@import 'material/mat-paginator';
@import 'material/mat-progress-bar';
@import 'material/mat-sidenav';
@import 'material/mat-snackbar';
@import 'material/mat-tabs';
@import 'material/mat-table';
@import 'material/mat-toolbar';
@import 'material/mat-tooltip';
@import 'material/forms/mat-checkbox';
@import 'material/forms/mat-datepicker';
@import 'material/forms/mat-form-field';
@import 'material/forms/mat-radio-button';
@import 'material/forms/mat-select';
@import 'material/forms/mat-slider';
@import 'material/forms/mat-slide-toggle';
@import 'material/forms/mat-stepper';

//@import 'layout/container';
@import 'layout/scrollbar';

// Components
@import 'components/notifier';

// Module Specific
@import 'module-specific/optimisation';
@import 'module-specific/style-guide';

// Legacy Components
@import 'components/defaultform';
@import 'components/primaryBtn';
@import 'components/secondaryBtn';
@import 'components/cancelBtn';
@import 'components/linkBtn';
@import 'components/searchfield';

// Box Components
@import "components/box";

