.mat-tab-label {
  @include text-body;
  font-weight: 400;
  color: #fff;
  transition: all 0.5s;

  //&-content {
  //  line-height: 1;
  //  padding-top: 10px;
  //  padding-bottom: 10px;
  //}

  &.mat-tab-label-active,
  &:hover {
    opacity: 1;
    color: map_get($colors-v2, 'primary');
    font-weight: 700;
  }
}

.mat-tab-body {
  margin: 1rem 0;

  animation: mat-tab-fade-out 0.5s;
  opacity: 0;

  &.mat-tab-body-active {
    animation: mat-tab-fade-in 0.5s;
    opacity: 1;
  }
}

@keyframes mat-tab-fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mat-tab-fade-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
