@import '../_vars/material-vars';

// Vendor
$ng-select-highlight: #ccc;
$ng-select-primary-text: $light-primary-text;
//$ng-select-primary-light-text: brown;
$ng-select-secondary-text: $light-primary-text;
//$ng-select-secondary-light-text: yellow;
$ng-select-disabled-text: $light-primary-text;
$ng-select-divider: map-get($colors, 'primary');
$ng-select-bg: map-get($colors, 'grey');

@import '~@ng-select/ng-select/scss/material.theme.scss';
//.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
//  color: white !important;
//}

.ng-dropdown-panel {
  &.ng-select-bottom,
  &.ng-select-top {
    box-shadow: none;
  }

  .ng-dropdown-panel-items {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;

    overflow: hidden;

    .ng-option.ng-option-selected {
      background: rgba(255, 255, 255, 0.12);
      color: $light-primary-text;
    }
  }
}

.ng-input {
  input {
    position: relative;
    cursor: pointer;
    color: $ng-select-primary-text;
  }
}

.ng-select {
  .ng-select-container {
    min-height: 49.75px;
    cursor: pointer;

    .ng-placeholder {
      top: 23px;
      color: rgb(204, 204, 204) !important;
    }

    .ng-value-container {
      border-top: 1.25em solid transparent;
    }

    &::after {
      border-bottom-color: rgba(255, 255, 255, 0.7);
      border-bottom-width: 1px;
    }
  }

  &.ng-invalid.ng-dirty {
    .ng-select-container {
      .ng-arrow-wrapper .ng-arrow {
        color: map-get($colors, 'warn1');
      }

      &::after {
        border-bottom-color: map-get($colors, 'warn');
      }
    }
  }

  &.ng-select-focused {
    .ng-select-container {
      .ng-arrow-wrapper .ng-arrow {
        color: map-get($colors, 'primary');
      }

      &::after {
        border-bottom-color: map-get($colors, 'primary');
      }
    }
  }

  .ng-select-container .ng-arrow-wrapper {
    .ng-arrow {
      border: none !important;
      position: relative;
      width: 8px;
      height: 5px;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 8px;
        height: 5px;
        background-size: 8px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='Icon_Chevron' data-name='Icon &gt; Chevron' width='16' height='9.73' viewBox='0 0 16 9.73'%3E%3Cpath id='Path_3506' data-name='Path 3506' d='M12.986,18.726a1.025,1.025,0,0,1-.73-.32L5.411,11.46A1.607,1.607,0,0,1,5.263,9.5,1.009,1.009,0,0,1,6.871,9.32l6.115,6.224,6.115-6a1.006,1.006,0,0,1,.84-.3,1.1,1.1,0,0,1,.769.511,1.593,1.593,0,0,1,.284,1.057,1.486,1.486,0,0,1-.443.972L13.7,18.49A.993.993,0,0,1,12.986,18.726Z' transform='translate(-4.999 -8.999)' fill='%23fff'/%3E%3C/svg%3E");
      }
    }
  }
}
