@mixin text-h1 {
  font-size: 1.802rem;
}

@mixin text-h2 {
  font-size: 1.602rem;
}

@mixin text-h3 {
  font-size: 16.6;
}

@mixin text-h4 {
  font-size: 1.266rem;
}

@mixin text-h5 {
  font-size: 1.125rem;
  font-weight: 600;
}

@mixin text-h6 {
  font-size: 1rem;
}

@mixin text-body {
  font-size: 0.889rem;
  line-height: 1.1875rem;
}

@mixin text-body-2 {
  font-size: 0.79rem;
}

@mixin text-small {
  font-size: 0.702rem;
}

@mixin text-smallest {
  font-size: 0.624rem;
}
