@import '../_vars/vars';

.mat-button-toggle-group {
  &.mat-button-toggle-group-appearance-standard {
    &,
    .mat-button-toggle + .mat-button-toggle {
      border-color: var(--primary);
    }
    border-radius: 2px;
  }
}

.mat-button-toggle {
  &.mat-button-toggle-appearance-standard {
    background: none;
    font-weight: 500;

    &:hover,
    &.mat-button-toggle-checked {
      background: var(--primary-light) !important;
    }

    .mat-button-toggle-label-content {
      line-height: 32px;
    }
  }
}
