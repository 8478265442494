.guide-item {
  &__title {
    background: #2f3742;
    padding: 1rem;
    margin-bottom: 1rem;
    font-weight: 700;
  }
  margin-bottom: 2rem;

  &__container {
    padding: 0 1rem;
  }
}
