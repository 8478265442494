.mat-form-field {
  line-height: 1.25;

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    &,
    .eva {
      font-size: 1rem;
    }
  }

  .mat-form-field-label {
    color: map_get($colors-text, 'soft');
  }

  &.mat-error {
    .mat-select-value-text {
      color: #fff;
    }
  }
}

.mat-form-field-subscript-wrapper {
  @include text-body-2;

  .mat-hint {
    color: #fff;

    &.mat-form-field-hint-end {
      color: map_get($colors-text, 'soft');
    }
  }
}

// Helpers
.mat-form-field.pb-0 .mat-form-field-wrapper {
  //margin-bottom: calc(-1.34375em + 0.25em);
  margin-bottom: -1.34375em;
}

// Should be used to remove the extra padding created for the label
.mat-form-field.no-label {
  margin-top: -0.84375em;
}

.mat-form-field-appearance-legacy,
.mat-form-field-appearance-standard {
  .mat-form-field-prefix {
    top: 0.175rem;
  }
}

.mat-form-field.no-underline {
  .mat-form-field-underline {
    display: none;
  }
}

// Speak with Andrew
//.mat-form-field-appearance-standard {
//  .mat-form-field-flex {
//    padding-top: 0!important;
//    display: flex;
//    align-items: center;
//  }
//
//  .mat-form-field-infix {
//    border-top: 0!important;
//  }
//}

//.mat-form-field-appearance-outline {
//  .mat-form-field-prefix {
//    margin-right: 0.25rem;
//
//    mat-icon .eva {
//      font-size: 24px;
//    }
//  }
//}

.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
  place-self: center;
}

.mat-form-field-appearance-outline {
  .mat-input-element,
  mat-date-range-input {
    position: relative;
    bottom: 0.25em;
  }

  mat-icon .eva {
    display: block;
  }

  mat-label {
    overflow-y: visible;
  }
}
