.mat-select-arrow-wrapper {
  .mat-select-arrow {
    border: none !important;
    position: relative;
    width: 8px;
    height: 5px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 8px;
      height: 5px;
      background-size: 8px;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='Icon_Chevron' data-name='Icon &gt; Chevron' width='16' height='9.73' viewBox='0 0 16 9.73'%3E%3Cpath id='Path_3506' data-name='Path 3506' d='M12.986,18.726a1.025,1.025,0,0,1-.73-.32L5.411,11.46A1.607,1.607,0,0,1,5.263,9.5,1.009,1.009,0,0,1,6.871,9.32l6.115,6.224,6.115-6a1.006,1.006,0,0,1,.84-.3,1.1,1.1,0,0,1,.769.511,1.593,1.593,0,0,1,.284,1.057,1.486,1.486,0,0,1-.443.972L13.7,18.49A.993.993,0,0,1,12.986,18.726Z' transform='translate(-4.999 -8.999)' fill='%23fff'/%3E%3C/svg%3E");
    }
  }
}

.mat-option {
  &.mat-active,
  &:hover:not(.mat-option-disabled),
  &:focus:not(.mat-option-disabled) {
    background: rgba(255, 255, 255, 0.12);
  }
}

.mat-select-clear {
  font-weight: 500;
  cursor: pointer;
  color: map-get($colors-v2, 'primary');
  padding: 0.875rem 0.5rem;
  text-align: right;
  border-bottom: 1px map-get($background-colors, 'red');
}
