.mat-paginator {
  background: transparent !important;

  &,
  .mat-paginator-page-size .mat-select-trigger {
    @include text-body-2;
  }

  .mat-paginator-range-label {
    color: #fff;
  }
}
