mat-dialog-container.mat-dialog-container {
  background: #1E1E1E;

  .eva-close-outline {
    float: right;
    font-size: 18px;
    margin: -10px -10px 0 0;
    cursor: pointer;
  }
}


